<template>
  <router-view />
</template>

<script setup lang="ts">
import { useUpdateStore } from '@auth/stores/useUpdateStore';
import { onMounted } from 'vue';
onMounted(() => {
  const updateStore = useUpdateStore();
  updateStore.checkForUpdates();
});
</script>
