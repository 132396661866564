import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),

    children: [
      { path: '', component: () => import('pages/IndexPage.vue'), name: 'home' },
      { path: '/crudmanager', component: () => import('@crud/crudmanager/CrudManager.vue') },
      { path: 'policy', component: () => import('pages/PolicyPage.vue') },
      { path: '/parts/clean', component: () => import('pages/PartsCleanPage.vue') },
      { path: '/parts/temporary', component: () => import('pages/TemporaryPartsPage.vue') },
    ],
  },

  /*
   * Always leave this as last one,
   * but you can also remove it
   */
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
